body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root{
  min-height: 100vh;
  background-color: #f8f8f8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.flex{
  display: flex;
}
.flex-column{
  flex-direction: column;
  
  
}

.app-container{
  min-height: 70vh;
}

.ms-footer {
  margin-top: auto;
}
.uhf-header-main ul.f-multi-column {
  right: 0 !important;
  z-index: 1000 !important;
  
}
